/* You can add global styles to this file, and also import other style files */

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body > :not(#print) {
    display: none;
  }
}
